<!--
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-03-10 10:55:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\App.vue
-->
<template>
  <div v-if="activity">
    <!-- <component :is="GameComponent" v-if="GameComponent" style="position:fixed;z-index:9999;left:0;top:0;right:0;bottom:0"></component> -->
    <router-view />
    <van-tabbar v-show="$route.path.indexOf('/game/') == -1" route class="footer-navigator" inactive-color="#898B8D" active-color="var(--main-color)" :style="{ transform: 'translateY(' + (isShowMenu ? '0' : '200%') + ')' }">
      <van-tabbar-item @click="onUserLogSendBtn(1)" class="footer-navigator-center" icon="coupon-o" replace to="/checkin">首页</van-tabbar-item>
      <!-- <van-tabbar-item class="footer-navigator-center" icon="coupon-o" replace to="/game" v-else>
				首页
			</van-tabbar-item> -->
      <van-tabbar-item class="footer-navigator-contact" icon="service-o" @click="onContact(activity.phone)" v-if="activity.phone">
        客服
      </van-tabbar-item>
      <van-tabbar-item @click="onUserLogSendBtn(2)" class="footer-navigator-record" icon="balance-list-o" replace to="/record">
        我的
      </van-tabbar-item>
    </van-tabbar>

    <div class="common-tip" :class="{ active: tip.show, 'move-in': tip.in, 'move-out': tip.out }" @touchmove="$event.preventDefault()">
      <div class="common-tip-container">
        <img class="common-tip-image" :src="tip.image" v-if="tip.image" />
        <div class="common-tip-icon" v-else>!</div>
        <h3>{{ tip.message }}</h3>
        <p>{{ tip.summary }}</p>
      </div>
    </div>

    <van-overlay :show="show" @click="show = false" z-index="9999" />
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "App",
  data() {
    return {
      show: false,
      tip: {
        message: "",
        show: false,
        in: false,
        out: false,
      },
      isShowMenu: true,
      justGame: false,
      GameComponent: null,
    };
  },
  watch: {
    $route: {
      handler: function(to, from) {
        window.scrollTo(0, 0);
      },
      deep: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    activity() {
      return this.$store.getters.activity;
    },
    checkin() {
      return this.$store.getters.checkin;
    },
  },
  created() {
    Vue.prototype.$GlobalShowMessage = this.onShowMessage;

    if (!this.$utils.RequestQuery("preview")) {
      this.login();
      this.jssdk();
    } else {
      this.getActivityDetails();
    }

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    }, 100);
  },
  methods: {
    onUserLogSendBtn(i) {
      let buttons = {
        1: "导航栏首页按钮",
        2: "导航栏我的按钮",
        3: "导航栏客服按钮",
      };

      let pages = {
        ActivityCheckin: "首页",
        ActivityGame: "游戏页",
        ActivityMessage: "信息提示页",
        ActivityRecord: "兑奖记录页",
      };

      this.$store.dispatch("onUserLogSend", {
        pageName: pages[this.$route.name] || "通用页面",
        behavior: { click: buttons[i] || "通用按钮" },
      });
    },
    jssdk() {
      let wx = window.wx,
        url = location.href.replace(location.hash, "");

      this.$api.wechat.jssdk({ url: url }).then((config) => {
        wx.config({
          //debug: true,
          appId: config.appId,
          timestamp: config.timestamp,
          nonceStr: config.nonceStr,
          signature: config.signature,
          jsApiList: ["hideAllNonBaseMenuItem", "hideOptionMenu", "hideMenuItems", "updateAppMessageShareData", "updateTimelineShareData", "openLocation", "getLocation"],
        });
      });

      wx.error((response) => {
        console.log(response);
      });
    },
    login() {
      if (!this.user) {
        this.$api.wechat
          .user()
          .then((response) => {
            if (response) {
              this.$store.commit("setUser", response);
            } else {
              //TODO 获取用户异常
            }
            this.getActivityDetails();
          })
          .catch((error) => {
            //TODO 获取用户异常
            this.getActivityDetails();
          });
      } else {
        this.getActivityDetails();
      }
    },
    getActivityDetails() {
      let activity = this.$utils.RequestQuery("activity", true);

      if (!activity) {
        return;
      }
      this.show = true;

      this.$api.activity
        .details({ code: activity })
        .then((response) => {
          this.show = false;
          this.$store.commit("setActivity", response);
          document.getElementById("app-title").innerText = response.name;

          if (response.share == "1") {
            wx.ready(() => {
              console.log(response);
              let title = response.shareTitle,
                desc = response.shareDesc,
                link = response.shareLink || location.href.replace(location.hash, ""),
                img = response.shareImg;

              wx.updateAppMessageShareData({ title: title, desc: desc, link: link, imgUrl: img });
              wx.updateTimelineShareData({ title: title, link: link, imgUrl: img });
            });
          } else {
            wx.ready(() => {
              wx.hideAllNonBaseMenuItem();
              wx.hideOptionMenu();
              wx.hideMenuItems({
                menuList: ["menuItem:share:appMessage", "menuItem:share:timeline", "menuItem:share:qq", "menuItem:share:weiboApp", "menuItem:favorite", "menuItem:share:facebook", "menuItem:share:QZone", "menuItem:copyUrl"],
              });
            });
          }

          (function() {
            var body = document.getElementsByTagName("body")[0];
            document.title = response.name;
            var iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.setAttribute("src", "/favicon.ico");
            var d = function() {
              setTimeout(function() {
                iframe.removeEventListener("load", d);
                document.body.removeChild(iframe);
              }, 0);
            };
            iframe.addEventListener("load", d);
            document.body.appendChild(iframe);
          })();

          response.color && document.documentElement.style.setProperty(`--main-color`, response.color);

          if (!this.$utils.RequestQuery("preview") && response.flag) {
            this.$router.replace({ name: "ActivityMessage" });
            return;
          }

          /* if (Object.keys(this.activity.accountType).length + Object.keys(this.activity.verification).length === 0)
							{
								this.justGame = true;
		
								if (this.$route.name !== 'ActivityGame')
								{
									this.$router.replace({ name: 'ActivityGame' });
								}
							} */
        })
        .catch(() => {
          this.show = false;
        });
    },
    onContact(mobile) {
      this.onUserLogSendBtn(3);
      let link = document.createElement("a");

      link.style.display = "none";
      link.href = "tel:" + mobile;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // onShowMessage(message, summary, image) {
    //   this.tip.message = message || '您暂无抽奖资格哦！'
    //   this.tip.summary = summary || ''
    //   this.tip.image = image || ''
    //   this.tip.show = true
    //   this.tip.out = false
    //   this.tip.in = true

    //   setTimeout(() => {
    //     this.tip.in = false
    //     this.tip.out = true

    //     setTimeout(() => {
    //       this.tip.show = false
    //       this.tip.out = false
    //     }, 500)
    //   }, 2700)
    // },
    onShowMessage(message, summary, image, cb) {
      this.tip.message = message || "您暂无抽奖资格哦！";
      this.tip.summary = summary || "";
      this.tip.image = image || "";
      this.tip.show = true;
      this.tip.out = false;
      this.tip.in = true;
      this.timerCb = cb;

      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.tip.in = false;
        this.tip.out = true;

        setTimeout(() => {
          this.tip.show = false;
          this.tip.out = false;
          if (cb) {
            cb();
          }
        }, 500);
      }, 2700);
    },
    onToggleMenu(status) {
      this.isShowMenu = status;
    },
    onLoadGame(id, src, component) {
      if (!document.getElementById(id)) {
        let script = document.createElement("script");

        script.id = id;
        script.src = src;
        document.body.appendChild(script);

        script.onload = () => {
          console.log("动态注册组件", component, window.myComvue.default);
          Vue.component(component, window.myComvue.default);

          this.GameComponent = component;
        };
      }
    },
  },
};
</script>
