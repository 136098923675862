<!--
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-01-17 17:23:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\pages\CouponList.vue
-->
<template>
  <div style="position:relative;z-index:2;">
    <div class="list-empty" v-if="!coupon.list.length && isRequested">
      <img src="common/images/empty.png" />
      <p>当前没有任何记录哦～</p>
    </div>
    <div v-else>
      <div class="record-list" :finished="coupon.finished" finished-text="没有更多了">
        <div class="record-item1" :style="item.awardType == '5' ? 'align-items: center' : ''" v-for="(item, index) in coupon.list" :key="index">
          <!-- <span class="record-sn">{{item.datas.activityName}}</span> -->
          <div class="record-item1-a">
            <h3 class="record-item1-a1">{{ item.awardName }}</h3>
            <span class="record-item1-a2">{{ item.createTime }} 兑换</span>
            <div v-if="item.awardType == '1'" style="font-size: 12px;color: #333;margin-top: 10px">立减金已成功发放，请前往“微信-我-卡包-券和礼品”查看</div>
          </div>
          <div class="record-item1-b1" v-if="item.awardType == '1'">
            ￥
            <strong style="font-size: 5rem">{{ item.awardMoney }}</strong>
          </div>
          <div class="record-item1-b2" v-if="item.awardType == '5'">
            <img style="height: 100%" :src="item.img" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityRecord",
  data() {
    return {
      coupon: {
        //list: MockListRequest(10),
        list: [],
        loading: false,
        finished: false,
        refreshing: false,
        pageNum: 0,
      },
      isRequested: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    activity() {
      return this.$store.getters.activity;
    },
    checkin() {
      return this.$store.getters.checkin;
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch("onUserLogSend", {
      pageName: "兑奖记录页",
      behavior: { visist: "兑奖记录页" },
    });
  },
  watch: {
    user: {
      handler: function(n, o) {
        this.activity && this.activity.activityId && this.user && this.onCouponLoad();
      },
      immediate: true,
    },
    activity: {
      handler: function(n, o) {
        this.activity && this.activity.activityId && this.user && this.onCouponLoad();
      },
      immediate: true,
    },
  },
  methods: {
    onCouponLoad() {
      let coupon = this.coupon;

      if (coupon.finished) return;

      if (this.user) {
        console.log(1111111);
        coupon.finished = true;
        this.$api.activity
          .record(
            {
              activityId: this.activity.activityId,
              openId: this.user.openId,
              pageNum: ++coupon.pageNum,
              pageSize: 100,
            },
            (bag) => {
              /* if (coupon.list.length >= bag.total && bag.data < 10)
								  {
									  coupon.finished = true;
								  } */

              this.isRequested = true;
              coupon.loading = false;
              console.log(222222);
            }
          )
          .then((response) => {
            console.log(33333);
            if (coupon.refreshing) {
              coupon.list = [];
              coupon.refreshing = false;
            }

            coupon.list.splice(coupon.list.length, 0, ...response);
            coupon.loading = false;
            console.log(coupon.list);
          })
          .catch((error) => {
            console.log(error);
            this.isRequested = true;
            coupon.loading = false;
          })
          .then((response) => {
            console.log(5555);
          });
      }
    },
    onCouponRefresh() {
      this.coupon.pageNum = 0;
      this.coupon.finished = false;
      this.coupon.loading = true;

      //this.onCouponLoad();
    },
  },
};
</script>

<style scoped>
.record-item1 {
  display: flex;
  /* align-items: center; */
  background: #ffffff;
  border-radius: 0.8rem;
  margin-bottom: 2.4rem;
}
.record-item1-a {
  flex: 1;
  width: 0;
  padding: 2.6rem 3rem;
}
.record-item1-b1 {
  width: 20rem;
  background-color: var(--main-color);
  /* height: 15.2rem; */
  border-radius: 0 0.8rem 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.record-item1-a1 {
  font-size: 3rem;
  font-weight: bold;
  color: #212223;
  line-height: 4.2rem;
  margin-bottom: 0.6rem;
}
.record-item1-a2 {
  font-size: 2.4rem;
  color: #999999;
  line-height: 3.3rem;
}
.record-item1-b2 {
  width: 11.2rem;
  height: 11.2rem;
  padding: 2.6rem 3rem 2.6rem 0;
}
</style>
