/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2021-11-30 16:52:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\main.js
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import api from '@/common/api';
import utils from '@/common/utils';

Vue.prototype.$api = api;
Vue.prototype.$utils = utils;

import { setStatusCallback } from '@/common/api/axios';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	created()
	{
		let error = response => this.$toast({ message: response.message || '请求出错，请稍后再试' });

		setStatusCallback('OFFLINE', error);
		setStatusCallback(401, error);
		setStatusCallback(500, error);
	},
	mounted()
	{
		let error = response =>
		{
			if (response.stop) return;
			
			this.$children[0].onShowMessage(response.message, response.size);
		}

		setStatusCallback('OFFLINE', error);
		setStatusCallback(401, error);
		setStatusCallback(500, error);
	},
	render: h => h(App)
}).$mount('#app');
