<!--
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-03-01 11:31:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\pages\CouponDetail.vue
-->
<template>
  <div>
    <div style="position:relative;z-index:2;">
      <div
        :style="{
          width: width,
          height: height,
          backgroundImage: activity && activity.activityIndex ? `url('${activity.activityIndex}')` : '',
        }"
        class="exchange-page-fullscreen"
      >
        <div class="ruler-button" @click="modalRulerBtn" :style="{ transform: modal.ruler ? 'translateX(100%)' : 'translateX(0)' }" v-if="activity.rule">
          活动规则
        </div>
        <div class="ruler-privacy" @click="modalPrivacyBtn" :style="{ transform: modal.privacy ? 'translateX(100%)' : 'translateX(0)' }" v-if="activity.privacy">
          隐私政策
        </div>
        <form class="exchange-form" onsubmit="return false" :style="{ bottom: foucus ? '30vw' : '35vw' }" v-if="activity.activityId">
          <template v-for="(item, key) in accounts">
            <template v-if="key != '11'">
              <label :key="`accountType${key}`" v-if="key !== '7'">
                <input v-model="form[`accountType${key}`]" type="text" :placeholder="`请输入${item.label}`" @focus="onFocus" @blur="onBlur(form, `accountType${key}`)" />
              </label>
              <template v-else>
                <label :key="`_accountType${key}`">
                  <input v-model="form[`commonMobile`]" type="text" :placeholder="`请输入手机号`" @focus="onFocus" @blur="onBlur(form, `commonMobile`)" />
                </label>
                <label :key="`accountType${key}`">
                  <input v-model="form[`accountType${key}`]" type="text" :placeholder="`请输入${item.label}`" @focus="onFocus" @blur="onBlur(form, `accountType${key}`)" />
                </label>
              </template>
            </template>
            <!-- 
						<template v-else>
							<input style="display:none;" v-model="form[`accountType${key}`]" type="text" :key="key" />
						</template> -->
          </template>
          <div v-if="isCaptcha" style="display:flex;">
            <label>
              <input v-model="form[`verification${isCaptcha}`]" maxlength="4" :placeholder="`请输入${verifications[isCaptcha].label}`" @focus="onFocus" @blur="onBlur(form, `verification${isCaptcha}`)" />
            </label>
            <div class="code-content" @click="onRefreshValidate">
              <img class="code-validate-image" :src="validate.image" v-if="validate.image" />
            </div>
          </div>

          <label v-if="isVerificationMobile && !isAccountMobile">
            <input v-model="form[`commonMobile`]" type="text" :placeholder="`请输入手机号`" @focus="onFocus" @blur="onBlur(form, `commonMobile`)" />
          </label>
          <label v-if="isVerificationMobile">
            <input v-model="form[`verification${isVerificationMobile}`]" maxlength="4" :placeholder="`请输入${verifications[isVerificationMobile].label}`" @focus="onFocus" @blur="onBlur(form, `verification${isVerificationMobile}`)" />
            <button class="button-inner" @click="onGetMobileCode" :disabled="codeRemain > 0">
              {{ codeRemain ? `剩余${codeRemain}秒` : "获取验证码" }}
            </button>
          </label>
          <div class="form-action">
            <button class="button-default" @click="onExchangeSubmit" style="width:100%;">
              <van-loading v-if="loading2" type="spinner" style="display: inline-block" size="16px" />
              {{ activity.buttonName ? activity.buttonName : activities && Object.keys(activities).length ? "参与游戏" : "立即兑换" }}
            </button>
            <!-- <div v-if="locationGps.latitude" style="text-align: left">
              <p>latitude：{{ locationGps.latitude }}</p>
              <p>longitude：{{ locationGps.longitude }}</p>
              <p>accuracy：{{ locationGps.accuracy }}</p>
              <p>diff：{{ locationGps.diff }}</p>
            </div> -->
          </div>
        </form>
      </div>
    </div>
    <div class="exchange-page-ruler" v-if="modal.ruler" @click="modal.ruler = false">
      <div class="exchange-container" @click="$event.stopPropagation()">
        <h3>活动规则</h3>
        <div class="exchange-inner">
          <div v-html="activity.rule"></div>
          <!-- <ul>
            <li v-for="(item, index) in activity.rule" :key="index">{{ item }}</li>
          </ul> -->
        </div>
        <div class="exchange-close" @click="modal.ruler = false"></div>
      </div>
    </div>
    <div class="exchange-page-ruler" v-if="modal.privacy" @click="modal.privacy = false">
      <div class="exchange-container" @click="$event.stopPropagation()">
        <h3>隐私政策</h3>
        <div class="exchange-inner">
          <ul>
            <li v-for="(item, index) in activity.privacy" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="exchange-close" @click="modal.privacy = false"></div>
      </div>
    </div>

    <van-overlay :show="loading1" z-index="1300">
      <div class="pop-wrap">
        <van-loading type="spinner" size="24px" />
      </div>
    </van-overlay>

    <van-overlay :show="show" z-index="1200">
      <div class="pop-wrap">
        <div class="pop-wrap-a">
          <div class="pop-wrap-b">
            <p>不在活动指定范围内 请您移动后点击“刷新位置”按钮</p>
            <!-- <div style="text-align: left">
              <p>latitude：{{ locationGps.latitude }}</p>
              <p>longitude：{{ locationGps.longitude }}</p>
              <p>accuracy：{{ locationGps.accuracy }}</p>
              <p>diff：{{ locationGps.diff }}</p>
            </div> -->
          </div>
          <div class="pop-wrap-btn" @click="popGpsBtn">
            <div class="pop-wrap-btn2" :style="popGpsBtnStyle">
              <van-icon name="replay" />
            </div>
            <span style="padding-left: 6px">刷新位置</span>
            <div class="pop-wrap-btn1" @click.stop="popGpsBtnInfo">
              <van-icon name="question-o" size="16" />
              <span>其他问题</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "ActivityCheckin",
  data() {
    return {
      loading2: false,
      loading1: false,
      show: false,
      width: "100%",
      height: "100vh",
      foucus: false,
      codeRemain: 0,
      form: {
        code: null,
        key: null,
        secret: null,
        commonMobile: null,
      },
      validate: {
        image: null,
        value: null,
      },
      modal: {
        ruler: false,
        privacy: false,
      },
      locationData: {},
      locationGps: {},
      popGpsBtnStyle: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    activity() {
      return this.$store.getters.activity;
    },
    accounts() {
      return this.$store.getters.accounts;
    },
    activities() {
      return this.$store.getters.activities;
    },
    awards() {
      return this.$store.getters.awards;
    },
    verifications() {
      return this.$store.getters.verifications;
    },
    checkin() {
      return this.$store.getters.checkin;
    },
    isAccountMobile() {
      return this.$store.getters.isAccountMobile;
    },
    isCaptcha() {
      return this.$store.getters.isCaptcha;
    },
    isVerificationMobile() {
      return this.$store.getters.isVerificationMobile;
    },
  },
  watch: {
    activity(n, o) {
      if (n) {
        if (this.accounts["11"]) {
          this.loading1 = true;
          this.locationFlag(1);
        }
      }
    },
    accounts: {
      handler(n, o) {
        if (n) {
          for (let key in n) {
            this.$set(this.form, "accountType" + key, null);
          }
        }
      },
      immediate: true,
    },
    verifications: {
      handler(n, o) {
        if (n) {
          for (let key in n) {
            this.$set(this.form, "verification" + key, null);
          }
        }
      },
      immediate: true,
    },
    isCaptcha: {
      handler(n, o) {
        n && this.onRefreshValidate();
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.locationData = localStorage.locationData ? JSON.parse(localStorage.locationData) : {};
    if (this.accounts["11"]) {
      this.locationFlag(1);
    }
    // 浏览页面
    window.setTimeout(() => {
      this.$store.dispatch("onUserLogSend", {
        pageName: "首页",
        behavior: { visist: "首页" },
      });
    }, 500);
  },
  methods: {
    popGpsBtnInfo() {
      this.$router.push({
        path: "/message",
        query: {
          type: "msgErr",
        },
      });
    },
    popGpsBtn() {
      this.locationFlag(1);
    },
    locationFlagOk() {
      this.ajax({
        url: `https://restapi.amap.com/v3/geocode/regeo?location=${this.locationGps.longitude},${this.locationGps.latitude}&key=b5fb75494b500272c6e109fad578662a`,
        success: (res) => {
          let res1 = res.regeocode;
          if (res1) {
            this.locationData = res1;
            this.locationData.longitude = this.locationGps.longitude;
            this.locationData.latitude = this.locationGps.latitude;
            this.locationData.activityId = this.activity.activityId;
            localStorage.locationData = JSON.stringify(this.locationData);
            this.isMyGps();
          }
        },
      });
    },
    locationFlagErr() {
      this.loading1 = false;
      this.show = true;
    },
    locationFlag(num) {
      if (!this.popGpsBtnStyle) {
        this.popGpsBtnStyle = "transition: all 0.5s linear;transform: rotate(360deg);";
        setTimeout(() => {
          this.popGpsBtnStyle = "";
        }, 500);
      }
      if (this.locationData.activityId !== this.activity.activityId) {
        this.locationData = {};
        localStorage.locationData = JSON.stringify(this.locationData);
      }

      if (num !== 1) {
        this.loading1 = false;
        this.isMyGps();
        return;
      }

      window.wx.ready(() => {
        window.wx.getLocation({
          type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: (res) => {
            this.loading1 = false;
            let data1 = {
              latitude: res.latitude, // 纬度，浮点数，范围为90 ~ -90
              longitude: res.longitude, // 经度，浮点数，范围为180 ~ -180。
              accuracy: res.accuracy, // 位置精度
            };

            data1.diff = this.GetDistance(this.locationGps.latitude, this.locationGps.longitude, data1.latitude, data1.longitude);

            if (Number(data1.accuracy) > 300) {
              // IOS 模糊定位
              this.locationFlagErr();
              this.locationData = {};
              localStorage.locationData = JSON.stringify(this.locationData);
            } else {
              if (!this.locationGps || this.locationGps.latitude == undefined || data1.latitude == undefined) {
                this.locationGps = data1;
                this.locationFlagOk();
              } else {
                if (data1.diff > 10 || num === 1) {
                  this.locationGps = data1;
                  this.locationFlagOk();
                } else {
                  this.locationFlagErr();
                }
              }
            }
          },
          fail: () => {
            this.locationFlagErr();
          },
          cancel: () => {
            this.locationFlagErr();
          },
        });
      });
    },
    isMyGps() {
      // 判断 是否在适用位置
      let door = false;
      let gpsPoint = this.locationData.addressComponent;
      for (let i = 0; i < this.accounts["11"].datas.length; i++) {
        if (this.accounts["11"].type == 1) {
          let num1 = this.GetDistance(this.locationData.latitude, this.locationData.longitude, this.accounts["11"].datas[i].lat, this.accounts["11"].datas[i].lng);

          if (num1 < this.accounts["11"].datas[i].offset) {
            door = true;
            break;
          }
        } else if (this.accounts["11"].type == 0) {
          if (this.accounts["11"].datas[i].length === 1 && this.accounts["11"].datas[i][0].name === gpsPoint.province) {
            door = true;
            break;
          }
          if (this.accounts["11"].datas[i].length === 2 && this.accounts["11"].datas[i][0].name === gpsPoint.province && this.accounts["11"].datas[i][1].name === gpsPoint.city) {
            door = true;
            break;
          }
          if (this.accounts["11"].datas[i].length === 3 && this.accounts["11"].datas[i][0].name === gpsPoint.province && this.accounts["11"].datas[i][1].name === gpsPoint.city && this.accounts["11"].datas[i][2].name === gpsPoint.district) {
            door = true;
            break;
          }
        }
      }
      if (!door) {
        this.locationFlagErr();
      }
      return door;
    },
    ajax(obj) {
      var xhr = new XMLHttpRequest();
      xhr.open(obj.method || "GET", obj.url, true);
      xhr.setRequestHeader("content-type", "application/json");
      if (obj.headers) {
        for (let i in obj.headers) {
          xhr.setRequestHeader(i, obj.headers[i]);
        }
      }
      if (obj.responseType) {
        xhr.responseType = obj.responseType;
      }
      if (obj.cookie) {
        xhr.withCredentials = true;
      }
      if (obj.data) {
        xhr.send(JSON.stringify(obj.data));
      } else {
        xhr.send();
      }
      xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && xhr.status == 200) {
          obj.success && obj.success(JSON.parse(xhr.response));
        }
      };
      xhr.onerror = function() {
        obj.fail && obj.fail();
      };
    },
    // 方法定义 lat,lng
    GetDistance(lat1, lng1, lat2, lng2) {
      var radLat1 = (lat1 * Math.PI) / 180.0;
      var radLat2 = (lat2 * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      return s * 1000;
    },
    modalPrivacyBtn() {
      this.modal.privacy = true;
      this.$store.dispatch("onUserLogSend", {
        pageName: "首页",
        behavior: { click: "隐私政策按钮" },
      });
    },
    modalRulerBtn() {
      this.modal.ruler = true;
      this.$store.dispatch("onUserLogSend", {
        pageName: "首页",
        behavior: { click: "活动规则按钮" },
      });
    },
    onRemain() {
      this.codeRemain--;

      if (this.codeRemain > 0) {
        setTimeout(() => this.onRemain(), 1000);
      }
    },
    onRefreshValidate() {
      this.$api.base
        .captcha({ verification: this.isCaptcha, activityId: this.activity.activityId })
        .then((response) => {
          this.validate.image = response.img;
          this.form.key = response.uuid;
        })
        .catch((error) => console.log(error));
    },
    onGetMobileCode() {
      if (this.isCodeButton) {
        return;
      }

      let phone = this.form.commonMobile || this.form.accountType2;

      let data = {};

      for (let key in this.form) {
        let value = this.form[key];

        if (~key.indexOf("accountType")) {
          let index = parseInt(key.replace("accountType", ""));

          if (!value && index != 11) {
            this.$GlobalShowMessage(`请填写${this.accounts[index].label}`);
            return;
          }

          switch (index) {
            case 2:
              //手机号
              if (!/^1[3456789]\d{9}$/.test(value)) {
                this.$GlobalShowMessage("手机号格式错误");
                return;
              }
              break;
            case 5:
              //口令红包
              /* if (this.accounts[ index ].secrets !== value)
										  {
											  this.$GlobalShowMessage('口令填写不正确')
											  return
										  } */

              let ads = this.accounts[index].datas;

              if (!ads || !ads.length) {
                this.$GlobalShowMessage("口令填写不正确");
                return;
              }

              let success = false;

              ads.forEach((ad) => {
                if (ad.secrets == value) {
                  success = true;
                }
              });

              if (!success) {
                this.$GlobalShowMessage("口令填写不正确");
                return;
              }

              break;
            case 6:
              let adss = this.accounts[index].datas;

              if (!adss || !adss.length) {
                this.$GlobalShowMessage(`${this.accounts[index].label}格式不正确`);
                return;
              }

              let successs = false;

              adss.forEach((ad) => {
                if (ad.limits == value.length) {
                  successs = true;
                }
              });

              if (!successs) {
                this.$GlobalShowMessage(`${this.accounts[index].label}格式不正确`);
                return;
              }
              break;
            case 7:
              //手机号码&工号
              if (!/^1[3456789]\d{9}$/.test(this.form.commonMobile)) {
                this.$GlobalShowMessage(`手机号格式错误`);
                return;
              }
              break;
            case 11:
              break;
            default:
              if (this.accounts[index].limits !== value.length) {
                this.$GlobalShowMessage(`${this.accounts[index].label}格式不正确`);
                return;
              }
          }

          if (index === 7) {
            data[key] = { accountType: index, account: this.form.commonMobile + "," + this.form[key] };
          } else {
            data[key] = { accountType: index, account: this.form[key] };
          }
        } else if (~key.indexOf("verification")) {
          let index = parseInt(key.replace("verification", ""));

          if (!value && index != 2) {
            this.$GlobalShowMessage(`请填写${this.verifications[index].label}`);
            return;
          }

          let phone = this.form.commonMobile || this.form.accountType2;

          switch (index) {
            case 2:
              //短信验证码
              if (!/^1[3456789]\d{9}$/.test(phone)) {
                this.$GlobalShowMessage(`手机号格式错误`);
                return;
              }
              break;
            case 3:
              //数字验证码
              if (!/^[0-9]{4}$/.test(value)) {
                this.$GlobalShowMessage(`请填写4位${this.verifications[index].label}`);
                return;
              }
              break;
            case 4:
              //字母验证码
              if (!/^[a-zA-Z]{4}$/.test(value)) {
                this.$GlobalShowMessage(`请填写4位${this.verifications[index].label}`);
                return;
              }
              break;
            case 5:
              //数字字母验证码
              if (!/^[0-9a-zA-Z]{4}$/.test(value)) {
                this.$GlobalShowMessage(`请填写4位${this.verifications[index].label}`);
                return;
              }
              break;
          }

          data[key] = { verification: index, phone: phone, code: this.form[key], key: this.form.key };
        }
      }

      for (let key in data) {
        data[key] = JSON.stringify(data[key]);
      }

      this.isCodeButton = true;
      this.$api.base
        .captcha({
          verification: this.isVerificationMobile,
          phone: phone,
          activityId: this.activity.activityId,
          data: JSON.stringify(data),
        })
        .then((response) => {
          this.$GlobalShowMessage("验证码已发送");

          this.isCodeButton = false;
          this.form.key = response.uuid;
          this.codeRemain = 60;
          this.onRemain();
        })
        .catch((response) => {
          this.isCodeButton = false;
        });
    },
    onExchangeSubmit() {
      if (this.loading2) return;
      if (this.$utils.RequestQuery("preview")) {
        if (Object.keys(this.activities).length) {
          this.$router.replace({ name: "ActivityGame", params: {} });
        } else {
          this.$GlobalShowMessage("页面预览测试点击");
        }
        return;
      }

      let data = {};

      for (let key in this.form) {
        let value = this.form[key];

        if (~key.indexOf("accountType")) {
          let index = parseInt(key.replace("accountType", ""));

          if (!value && index !== 11) {
            this.$GlobalShowMessage(`请填写${this.accounts[index].label}`);
            return;
          }

          switch (index) {
            case 2:
              //手机号
              if (!/^1[3456789]\d{9}$/.test(value)) {
                this.$GlobalShowMessage("手机号格式错误");
                return;
              }
              break;
            case 5:
              //口令红包
              /* if (this.accounts[ index ].secrets !== value)
										  {
											  this.$GlobalShowMessage('口令填写不正确')
											  return
										  } */
              let ads = this.accounts[index].datas;

              if (!ads || !ads.length) {
                this.$GlobalShowMessage("口令填写不正确");
                return;
              }

              let success = false;

              ads.forEach((ad) => {
                if (ad.secrets == value) {
                  success = true;
                }
              });

              if (!success) {
                this.$GlobalShowMessage("口令填写不正确");
                return;
              }
              break;
            case 6:
              let adss = this.accounts[index].datas;

              if (!adss || !adss.length) {
                this.$GlobalShowMessage(`${this.accounts[index].label}格式不正确`);
                return;
              }

              let successs = false;

              adss.forEach((ad) => {
                if (ad.limits == value.length) {
                  successs = true;
                }
              });

              if (!successs) {
                this.$GlobalShowMessage(`${this.accounts[index].label}格式不正确`);
                return;
              }
              break;
            case 7:
              //手机号码&工号
              if (!/^1[3456789]\d{9}$/.test(this.form.commonMobile)) {
                this.$GlobalShowMessage(`手机号格式错误`);
                return;
              }
              break;
            case 11:
              if (!this.isMyGps()) {
                return;
              }
              break;
            default:
              if (this.accounts[index].limits !== value.length) {
                this.$GlobalShowMessage(`${this.accounts[index].label}格式不正确`);
                return;
              }
          }

          if (index === 7) {
            data[key] = { accountType: index, account: this.form.commonMobile + "," + this.form[key] };
          } else if (index === 11) {
            let code = this.locationData.addressComponent.adcode;

            if (code) {
              if (this.accounts["11"].type == 0) {
                data[key] = {
                  accountType: index,
                  provinceCode: code.substr(0, 2) + "0000",
                  cityCode: code.substr(0, 4) + "00",
                  areaCode: code,
                };
              } else {
                data[key] = {
                  accountType: index,
                  latitude: this.locationData.latitude,
                  longitude: this.locationData.longitude,
                };
              }
            }
          } else {
            data[key] = { accountType: index, account: this.form[key] };
          }
        } else if (~key.indexOf("verification")) {
          let index = parseInt(key.replace("verification", ""));

          if (!value) {
            this.$GlobalShowMessage(`请填写${this.verifications[index].label}`);
            return;
          }

          let phone = this.form.commonMobile || this.form.accountType2;

          switch (index) {
            case 2:
              //短信验证码
              if (!/^1[3456789]\d{9}$/.test(phone)) {
                this.$GlobalShowMessage(`手机号格式错误`);
                return;
              }

              if (!/^[0-9]{4}$/.test(value)) {
                this.$GlobalShowMessage(`请填写4位短信验证码`);
                return;
              }
              break;
            case 3:
              //数字验证码
              if (!/^[0-9]{4}$/.test(value)) {
                this.$GlobalShowMessage(`请填写4位${this.verifications[index].label}`);
                return;
              }
              break;
            case 4:
              //字母验证码
              if (!/^[a-zA-Z]{4}$/.test(value)) {
                this.$GlobalShowMessage(`请填写4位${this.verifications[index].label}`);
                return;
              }
              break;
            case 5:
              //数字字母验证码
              if (!/^[0-9a-zA-Z]{4}$/.test(value)) {
                this.$GlobalShowMessage(`请填写4位${this.verifications[index].label}`);
                return;
              }
              break;
          }

          data[key] = { verification: index, phone: phone, code: this.form[key], key: this.form.key };
        }
      }

      for (let key in data) {
        data[key] = JSON.stringify(data[key]);
      }

      let sendData = Object.assign({ openId: this.user.openId, activityId: this.activity.activityId }, data);

      this.loading2 = true;
      this.$api.activity
        .check(sendData)
        .then((response) => {
          if (response) {
            // 立即兑换按钮埋点
            this.$store.dispatch("onUserLogSend", {
              pageName: "首页",
              behavior: { click: "立即兑换按钮" },
            });

            if (Object.keys(this.activities).length) {
              this.$router.replace({ name: "ActivityGame", params: { data: JSON.stringify(response) } });
            } else {
              this.$api.activity
                .luck(response)
                .then((response) => {
                  this.loading2 = false;

                  if (response) {
                    if (response.award) {
                      let resData = response.award;
                      this.luckClickNum = response.left || 0;
                      sessionStorage.setItem("luckClickNum:" + this.activity.activityId, this.luckClickNum);
                      if (resData.awardType === "1" || resData.awardType === "5") {
                        //this.$GlobalShowMessage('恭喜你中奖了', '立减金' + response.money + '元', response.img)
                        this.$GlobalShowMessage("恭喜你中奖了", resData.name, resData.img);
                        setTimeout(() => this.$router.replace({ name: "ActivityRecord" }), 3000);
                      }
                    } else {
                      if (response.notInTime == "1") {
                        this.$GlobalShowMessage("哎呀，没中奖。谢谢您的支持");
                      } else {
                        this.$GlobalShowMessage("来晚了，谢谢参与");
                      }
                    }
                  } else {
                    this.$GlobalShowMessage("来晚了，谢谢参与");
                  }
                })
                .catch((response) => {
                  this.loading2 = false;
                  this.$GlobalShowMessage(response.message || `请检查网络状态`);
                  //setTimeout(() => this.$router.replace({ name: 'ActivityRecord' }), 4000);
                });
            }
          } else {
            this.$GlobalShowMessage(`您暂无参与活动的资格`);
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    onFocus() {
      this.foucus = true;
      this.$parent.onToggleMenu(false);
    },
    onBlur(form, field) {
      form[field] = (form[field] || "").trim();
      setTimeout(() => {
        if (!this.foucus) {
          window.scrollTo(0, 0);
          this.$parent.onToggleMenu(true);
        }
      }, 100);

      this.foucus = false;
    },
    onPrevent(event) {
      !this.foucus && event.preventDefault();
    },
  },
};
</script>

<style scoped>
.pop-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.pop-wrap-a {
  width: 80vw;
  background: #fff;
  border-radius: 2.1333vw;
  text-align: center;
}
.pop-wrap-b {
  padding: 6.6667vw 4vw;
  font-size: 3.7333vw;
}
.pop-wrap-btn {
  border-top: 1px solid #d7d7d7;
  font-size: 4.2667vw;
  height: 13.3333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1989fa;
  position: relative;
}
.pop-wrap-btn1 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 3.4667vw;
  height: 100%;
  width: 22vw;
  height: 13.3333vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop-wrap-btn2 {
  height: 4.2667vw;
  width: 4.2667vw;
}
</style>
