/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2021-12-22 10:54:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\store.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import router from './router';

Vue.use(Vuex)

const vuexConfig = {
	state: {
		user: null,
		activity: null,
		checkin: null,
	},
	getters: {
		user: (state) =>
		{
			return state.user
		},
		activity: (state) =>
		{
			return state.activity || {}
		},
		accounts: (state) =>
		{
			return state.activity ? state.activity.accountType || [] : []
		},
		activities: (state) =>
		{
			return state.activity ? state.activity.activityType || [] : []
		},
		awards: (state) =>
		{
			return state.activity ? state.activity.awardType || [] : []
		},
		verifications: (state) =>
		{
			return state.activity ? state.activity.verification || [] : []
		},
		checkin: (state) =>
		{
			if (state.activity)
			{
				return state.checkin
			}

			return false
		},
		isAccountMobile: (state) =>
		{
			if (state.activity)
			{
				if (state.activity.accountType)
				{
					let account = state.activity.accountType

					if (account[ 2 ] || account[ 7 ])
					{
						return true
					}
				}
			}

			return false
		},
		isVerificationMobile: (state) =>
		{
			if (state.activity)
			{
				if (state.activity.verification && state.activity.verification[ 2 ])
				{
					return 2
				}
			}

			return false
		},
		isCaptcha: (state) =>
		{
			if (state.activity)
			{
				if (state.activity.verification)
				{
					let verification = state.activity.verification

					if (verification[ 3 ] || verification[ 4 ] || verification[ 5 ])
					{
						return Object.keys(verification)[ 0 ]
					}
				}
			}

			return false
		},
	},
	mutations: {
		setUser: (state, data) =>
		{
			state.user = data
		},
		setActivity: (state, data) =>
		{
			state.activity = data

			if (!state.activity.accountType || !Object.keys(state.activity.accountType).length)
			{
				state.checkin = true
			}
		},
		setCheckin: (state, data) =>
		{
			state.activity = data
		},
	},
	actions: {
		onUserLogSend({ commit }, data)
		{
			let send = {
				openId: vuexConfig.state.user?.openId,
				activityCode: window.localStorage.activity,
				pageName: data.pageName,
				pageLink: '#' + router.currentRoute.path,
				behavior: JSON.stringify(data.behavior),
			}

			/* _czc.push([ '_trackEvent', send.activityCode, send.behavior, send.pageName, send.pageLink, 'success' ]); */

			Vue.prototype.$api.base
				.userLogSend(send)
				.then(() => { })
				.catch(() => { })
		},
	},
	modules: {},
}

export default new Vuex.Store(vuexConfig)
