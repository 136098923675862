<!--
 * @Author: your name
 * @Date: 2021-11-05 09:04:05
 * @LastEditTime: 2022-09-02 15:39:04
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \SuperMalld:\Projects\微信营销系统\rouyi-client\src\client\pages\ActivityGame.vue
-->
<template>
  <div style="position: fixed;top:0;left:0;width:100%;height:100%">
    <div style="position:relative;z-index:1;">
      <!-- <component :is="'Question'" :activity="activity" :source="GameComponent.config" :user="user" :checkin="checkin" :activity-code="activityCode" :check="check" :next="onNext" :preview="$utils.RequestQuery('preview')" v-if="GameComponent"></component> -->
      <template v-if="loaded && GameComponent">
        <component :is="GameComponent.component" :activity="activity" :source="GameComponent.config" :user="user" :checkin="checkin" :activity-code="activityCode" :check="check" :next="onNext" :preview="$utils.RequestQuery('preview')" style="position:fixed;z-index:1;left:0;top:0;right:0;bottom:0"></component>
      </template>

      <div v-if="loading" style="position:fixed;left:0;top:0;right:0;bottom:0;z-index:20"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
/* import Question from "../components/Question.vue"; */

export default {
  name: "ActivityGame",
  components: {
    /* Question */
  },
  data() {
    return {
      config: {},
      activityCode: this.$utils.RequestQuery("activity", true),
      check: null,
      GameIndex: 0,
      GameComponent: null,
      GameComponents: [],
      loaded: false,
      enter: false,
      loading: false,
    };
  },
  watch: {
    activities: {
      handler(n, o) {
        if (!this.enter && this.activities && Object.keys(this.activities).length) {
          this.enter = true;
          let keys = Object.keys(this.activities);

          keys.forEach((type) => {
            if (this.activities[type].component) {
              this.onLoadGame(this.activities[type].component, (name) => {
                this.GameComponents.push({ config: this.activities[type], component: name });

                console.log("加载的游戏", this.GameComponents);

                if (this.GameComponents.length == keys.length) {
                  this.GameComponent = this.GameComponents[this.GameIndex];
                  this.loaded = true;
                }
              });
            } else if (type == "1") {
              /* this.GameComponents.push({ config: this.activities[ type ], component: "Question" });

							if (this.GameComponents.length == keys.length)
							{
								this.GameComponent = this.GameComponents[ this.GameIndex ];
								this.loaded = true;
							} */

              this.onLoadGame("question", (name) => {
                this.GameComponents.push({ config: this.activities[type], component: name });

                console.log("加载的游戏", this.GameComponents);

                if (this.GameComponents.length == keys.length) {
                  this.GameComponent = this.GameComponents[this.GameIndex];
                  this.loaded = true;
                }
              });
            }

            console.log(this.loaded);
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    activity() {
      return this.$store.getters.activity;
    },
    activities() {
      return this.$store.getters.activities;
    },
    checkin() {
      return this.$store.getters.checkin;
    },
  },
  created() {
    // 浏览页面
    window.setTimeout(() => {
      this.$store.dispatch("onUserLogSend", {
        pageName: "游戏页",
        behavior: { visist: "游戏页" },
      });
    }, 500);

    if (this.$route.params.data) {
      this.check = JSON.parse(this.$route.params.data);
    } else {
      if (this.$utils.RequestQuery("preview")) {
        return;
      }

      this.$api.activity.check(Object.assign({ openId: this.user.openId, activityId: this.activity.activityId })).then((response) => {
        if (response) {
          this.check = response;
        } else {
          this.$GlobalShowMessage(response.message || `您暂无参与活动的资格`);
        }
      });
    }
  },
  mounted() {
    setTimeout(() => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    }, 200);
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    document.documentElement.style.overflow = "auto";
  },
  methods: {
    onNext(success, fail, bag) {
      if (!success && !fail) {
        this.$router.replace({ name: "ActivityRecord" });
        //setTimeout(() => this.$router.replace({ name: 'ActivityRecord' }), 4000);
        console.log("子组件通知父组件彻底结束");
        return;
      }

      if (this.GameIndex < this.GameComponents.length - 1) {
        this.GameIndex++;
        this.GameComponent = this.GameComponents[this.GameIndex];
        return;
      }

      console.log("父组件的抽奖接口", success, fail);
      this.loading = true;
      this.$api.activity
        .luck(Object.assign(this.check, bag || {}))
        .then((msg) => {
          if (msg) {
            sessionStorage.setItem("luckClickNum:" + this.activity.activityId, msg.left || "");
            let response = msg;
            if (msg.award) {
              response = msg.award;
              sessionStorage.luckClickNum = msg.left;
            }
            if (response.awardType === "1" || response.awardType === "5") {
              if (success) {
                let result = success(msg);
                if (result) {
                  this.loading = false;
                  return;
                }
              }

              this.$GlobalShowMessage("恭喜你中奖了", response.name, response.img);
              setTimeout(() => {
                this.loading = false;
                this.$router.replace({ name: "ActivityRecord" });
              }, 4000);
            } else {
              if (msg.message || msg.left) {
                if (success) {
                  let result = success(msg);
                  if (result) {
                    this.loading = false;
                    return;
                  }
                }
              } else {
                if (fail && msg.notInTime != "1") {
                  let result = fail({ message: msg.msg || "来晚了，谢谢参与" });
                  if (result) {
                    this.loading = false;
                    return;
                  }
                } else {
                  this.$GlobalShowMessage("哎呀，没中奖。谢谢您的支持", null, null, () => {
                    setTimeout(() => {
                      this.loading = false;
                      window.location.replace("/");
                    }, 2200);
                  });
                  return;
                }
              }

              this.$GlobalShowMessage(response.name || "来晚了，谢谢参与", response.img);
            }
          } else {
            if (fail) {
              let result = fail({ message: "来晚了，谢谢参与" });
              if (result) {
                this.loading = false;
                return;
              }
            }

            this.loading = false;
            this.$GlobalShowMessage("来晚了，谢谢参与");
          }
        })
        .catch((response) => {
          //this.$GlobalShowMessage(response.message || `请检查网络状态`);
          //setTimeout(() => this.$router.replace({ name: 'ActivityRecord' }), 4000);

          if (fail) {
            let result = fail({ message: response.message || `请检查网络状态` });

            if (result) {
              this.loading = false;
              response.stop = true;
              return;
            }
          }
          this.loading = false;
          this.$GlobalShowMessage(response.name || "来晚了，谢谢参与", response.img);
        });
    },
    onLoadGame(component, success) {
      if (!document.getElementById(component)) {
        console.log(component);
        let script = document.createElement("script");

        script.id = component;
        script.src = `${location.protocol}//game.jszechao.com/static/common/${component}/${component}.js?t=` + new Date().getTime();
        //script.src = `/game/${component}/${component}.js`;
        document.body.appendChild(script);

        script.onload = () => {
          console.log("动态注册组件", component, window[component]);
          Vue.component(component, window[component]);

          success && success(component);
          //this.GameComponent = component;
        };
      } else {
        success && success(component);
      }
    },
  },
};
</script>
