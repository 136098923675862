<!--
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-02-28 10:48:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\pages\CouponDetail.vue
-->
<template>
  <div>
    <div v-if="type === 'msgErr'" class="message-fullscreen-a">
      <div class="message-fullscreen-a1" v-if="accounts && accounts.datas">
        <div v-if="locationData.formatted_address">
          <div class="message-fullscreen-a2">当前我的位置：</div>
          <div class="message-fullscreen-a3" @click="showMapItem1">
            <img class="message-fullscreen-a5" src="/common/images/p2.png" alt="" />
            <span class="message-fullscreen-a4">{{ locationData.formatted_address }}</span>
            <img class="message-fullscreen-a6" src="/common/images/p1.png" alt="" />
          </div>
        </div>
        <div v-if="accounts.type == 0">
          <div class="message-fullscreen-a2">省市区：</div>
          <div class="message-fullscreen-a3" v-for="(item, index) in accounts.datas" :key="index">
            <img class="message-fullscreen-a5" src="/common/images/p3.png" alt="" />
            <span class="message-fullscreen-a4 message-fullscreen-a7">
              <span v-for="(item1, index1) in item" :key="index1">{{ item1.name }}</span>
            </span>
          </div>
        </div>
        <div v-if="accounts.type == 1">
          <div class="message-fullscreen-a2">门店网点：</div>
          <div class="message-fullscreen-a3" v-for="(item, index) in accounts.datas" :key="index" @click="showMapItem(item)">
            <img class="message-fullscreen-a5" src="/common/images/p2.png" alt="" />
            <span class="message-fullscreen-a4">{{ item.address }}</span>
            <img class="message-fullscreen-a6" src="/common/images/p1.png" alt="" />
          </div>
        </div>
      </div>
      <img src="/common/images/gpsPage.png?v=2022.3.15" style="width: 100%" alt="" />
      <van-button type="info" class="message-fullscreen-b" @click="goBackBtn">返回活动</van-button>
    </div>
    <div class="message-fullscreen" v-else>
      <div class="message-content" v-if="activity" v-html="activity.flag"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityMessage",
  data() {
    return {
      type: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    activity() {
      return this.$store.getters.activity;
    },
    accounts() {
      return this.$store.getters.accounts["11"];
    },
    checkin() {
      return this.$store.getters.checkin;
    }
  },
  created() {
    // 浏览页面
    window.setTimeout(() => {
      this.$store.dispatch("onUserLogSend", {
        pageName: "信息提示页",
        behavior: { visist: "信息提示页" }
      });
    }, 500);
    if (this.$route.query) {
      this.type = this.$route.query.type || "";
    }
    this.locationData = localStorage.locationData ? JSON.parse(localStorage.locationData) : {};
  },
  methods: {
    goBackBtn() {
      this.$router.push("/");
    },
    showMapItem(i) {
      window.wx.ready(() => {
        window.wx.openLocation({
          latitude: i.lat, // 纬度，浮点数，范围为90 ~ -90
          longitude: i.lng, // 经度，浮点数，范围为180 ~ -180。
          name: i.address, // 位置名
          address: i.address // 地址详情说明
        });
      });
    },
    showMapItem1() {
      this.locationData = localStorage.locationData ? JSON.parse(localStorage.locationData) : {};
      if (this.locationData.latitude && this.locationData.longitude) {
        window.wx.ready(() => {
          window.wx.openLocation({
            latitude: this.locationData.latitude, // 纬度，浮点数，范围为90 ~ -90
            longitude: this.locationData.longitude, // 经度，浮点数，范围为180 ~ -180。
            name: this.locationData.formatted_address, // 位置名
            address: this.locationData.formatted_address // 地址详情说明
          });
        });
      }
    }
  }
};
</script>

<style scoped>
.message-fullscreen-a {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f2f2f6;
  overflow: hidden;
  overflow-y: auto;
}
.message-fullscreen-b {
  width: 86.6667vw;
  margin: 0 auto;
  display: block;
  margin-bottom: 13.3333vw;
}
.message-fullscreen-a1 {
  padding: 4vw;
}
.message-fullscreen-a2 {
  padding-bottom: 4vw;
  font-size: 4.2667vw;
  font-weight: bold;
}
.message-fullscreen-a3 {
  display: flex;
  align-items: center;
  padding: 3.0667vw 4vw;
  margin-bottom: 2.6667vw;
  background: #fff;
}
.message-fullscreen-a4 {
  color: #2c80ff;
  font-size: 3.2vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  padding-left: 20px;
}
.message-fullscreen-a5 {
  width: 3.8667vw;
  height: 5.0667vw;
}
.message-fullscreen-a6 {
  width: 3.4667vw;
  height: 3.4667vw;
}
.message-fullscreen-a7 {
  color: #898b8d;
}
</style>
