/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2021-11-09 17:19:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\client\router.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

import ActivityCheckin from './pages/ActivityCheckin';
import ActivityGame from './pages/ActivityGame';
import ActivityRecord from './pages/ActivityRecord';
import ActivityMessage from './pages/ActivityMessage';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function(location)
{
	return originalPush.call(this, location).catch(err => err);
};

const routes = [
	{
		path: '/',
		redirect: '/checkin'
	},
	{
		path: '/checkin',
		name: 'ActivityCheckin',
		component: ActivityCheckin
	},
	{
		path: '/game/:data',
		name: 'ActivityGame',
		component: ActivityGame
	},
	{
		path: '/record',
		name: 'ActivityRecord',
		component: ActivityRecord
	},
	{
		path: '/message',
		name: 'ActivityMessage',
		component: ActivityMessage
	}
];

const router = new VueRouter({
	routes
});

export default router;